<template>
  <div id="orders_element">
    <b-row>
      <b-col>
        <b-table
          ref="refInvoiceListTable"
          :items="childOrders"
          :busy="isBusy"
          responsive
          :fields="tableColumnsFinalOrders"
          primary-key="id"
          select-mode="single"
          show-empty
          empty-text="No se han encontrado datos"
          @sort-changed="onSortChanged"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>{{ $t('load') }}</strong>
            </div>
          </template>
          <template #cell(order_id)="data">
            <div class="d-flex justify-content-between">
              {{ data.item.node.orderId }}
              <b-badge
                v-if="data.item.node.status == 'A_7'"
                variant="info"
                class="ml-1 mr-1"
                style="height: max-content;"
              >
                {{ $t('pendingDelivery') }}
              </b-badge>
              <b-badge
                v-if="data.item.node.status == 'A_10'"
                variant="success"
                class="ml-1 mr-1"
                style="height: max-content;"
              >
                {{ $t('inTransit') }}
              </b-badge>
              <b-badge
                v-if="data.item.node.status == 'A_14'"
                variant="danger"
                class="ml-1 mr-1"
                style="height: max-content;"
              >
                {{ $t('errorPacklink') }}
              </b-badge>
              <b-badge
                v-if="data.item.node.isSpecial"
                variant="warning"
                class=""
                style="height: max-content;"
              >
                {{ $t('special') }}
              </b-badge>
            </div>
          </template>
          <template #cell(customer)="data">
            <div>
              {{ cleanUsername(data.item.node.customer.username) }}
            </div>
          </template>
          <template #cell(chargeToken)="data">
            <div>
              {{ data.item.node.chargeToken }}
            </div>
          </template>
          <template #cell(status)="data">
            <div class="d-flex justify-content-between align-items-center">
              <span>
                {{ status[data.item.node.status] }}
              </span>
            </div>
          </template>
          <template #cell(vendor)="data">
            <div>
              {{ data.item.node.vendor == null ? "" : data.item.node.vendor.vendorName }}
            </div>
          </template>
          <template #cell(shippingCost)="data">
            <div>
              {{ data.item.node.shippingCost }} {{ currencyType[currency] }}
            </div>
          </template>
          <template #cell(totals)="data">
            <div>
              {{ format(data.item.node.totalAmount) }} {{ currencyType[currency] }}
            </div>
          </template>
          <template #cell(created)="data">
            <div>
              {{ data.item.node.paymentDate ? fechaFormato(data.item.node.paymentDate) :
                fechaFormato(data.item.node.created) }}
            </div>
          </template>
          <template #cell(packlink)="data">
            <div>
              <b-link
                :href="'https://pro.packlink.es/private/shipments/' + data.item.node.shippingId + '/create/payment'"
                target="_blank"
              >
                {{ data.item.node.shippingId }}
              </b-link>
            </div>
          </template>
          <template #cell(show_details)="data">
            <b-button
              size="sm"
              :variant="data.detailsShowing ? 'danger' : 'success'"
              class="cursor-pointer"
              :disabled="data.item.node.products.edges.length == 0"
              @click="data.toggleDetails"
            >
              {{ data.detailsShowing ? $t('table.hidden') : $t('table.Show') }} productos
            </b-button>
          </template>
          <template #cell(accept)="">
            <!-- :disabled="data.item.node.status == 'A_2' ? false : true" -->
            <b-button
              size="sm"
              :variant="'success'"
              disabled
              class="cursor-pointer"
            >
              {{ $t('code.accept') }}
            </b-button>
          </template>
          <template #cell(decline)="data">
            <!-- :disabled="data.item.node.status == 'A_6' || data.item.node.status == 'A_8' ? true : false" -->
            <b-button
              size="sm"
              disabled
              :variant="'danger'"
              class="cursor-pointer"
              @click="data.toggleDetails"
            >
              {{ $t('dataGeneric.cancel') }}
            </b-button>
          </template>

          <template #row-details="row">
            <b-card :title="$t('Products')">
              <b-row class="mb-1">
                <b-col>
                  <h6>{{ $t('firstName') }}</h6>
                </b-col>
                <b-col>
                  <h6>{{ $t('quantity') }}</h6>
                </b-col>
                <b-col>
                  <h6>{{ $t('purchases.price') }}</h6>
                </b-col>
              </b-row>
              <b-row
                v-for="prod in row.item.node.products.edges"
                :key="prod.node.id"
                class="mb-1"
              >
                <b-col>{{ prod.node.itemName }}</b-col>
                <b-col>{{ prod.node.itemQuantity }} </b-col>
                <b-col>{{ prod.node.itemPrice }} {{ currencyType[currency] }}</b-col>
              </b-row>
              <b-row>
                <b-button
                  size="sm"
                  variant="danger"
                  @click="row.toggleDetails"
                >
                  {{ $t('dataGeneric.close') }}
                </b-button>
              </b-row>
            </b-card>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="d-flex ">
        <b-pagination
          v-if="rows > perPage"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          size="lg"
          @input="fetchData"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BSpinner,
  BButton,
  BTable,
  BBadge,
  BLink,
  BPagination,

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  messageError, cleanUsername, isEmpty, showToast,
} from '@/store/functions'

import axios from '@axios'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/flatpickr.css'
import statusOrder from '@core/utils/statusOrder'

import { getUserData } from '@/auth/utils'

const fileError = require('@/assets/images/backend/file-error.png')
const noCover = require('@/assets/images/backend/nocover.jpeg')

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BSpinner,
    BButton,
    BTable,
    BLink,
    BBadge,
    BPagination,

  },
  directives: {
    Ripple,
  },
  props: {
    idFinalOrder: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      userData: getUserData(),
      paidOrders: [],
      vendorData: null,
      orders: null,
      isEmpty,
      isBusy: false,
      allOrder: [],
      childOrders: [],
      vendor: '',
      allVendors: [],
      filterDate: '',
      currency: null,
      myId: null,
      orphan: false,
      tableColumnsFinalOrders: [
        { key: 'order_id', label: this.$t('orderId'), sortable: true },
        { key: 'customer', label: this.$t('Cliente') },
        { key: 'chargeToken', label: this.$t('idPaid') },
        { key: 'status', label: this.$t('editContent.status') },
        { key: 'shippingCost', label: this.$t('sendCost') },
        { key: 'totals', label: this.$t('Totales') },
        { key: 'created', label: this.$t('subscriptions.createdAt'), sortable: true },
        { key: 'packlink', label: this.$t('packlink') },
        { key: 'show_details', label: this.$t('Products') },

      ],
      // TODO: QUE PASA SI NECESITAMOS MAS MONEDAS
      currencyType: {
        EUR: '€',
        USD: '$',
        GBP: '£',
        ARS: this.$t('argentinePeso'),
        isInternal: false,
      },
      methodPayment: {
        STR: 'Stripe',
        APP: 'Apple InAppPurchase',
        GOW: 'Google InAppPurchase',
      },
      status: statusOrder,
      rows: 0,
      perPage: 20,
      currentPage: 1,
      brItems: [
        {
          id: null,
          text: this.$t('contents.start'),
        },
      ],
      navItems: [
        {
          id: null,
          text: this.$t('contents.start'),
        },
      ],
      orderBy: '-created',

    }
  },
  mounted() {
    try {
      this.vendor = this.userData.profile.profileVendor.edges[0].node.id
    } catch (error) {
      this.vendor = ''
    }
    this.getPayMethod()
    this.fetchData()
  },
  methods: {
    onSortChanged(sortInfo) {
      let sort = ''
      if (sortInfo.sortBy === 'created') {
        if (!sortInfo.sortDesc) {
          sort = '-paymentDate,-created'
        } else {
          sort = 'paymentDate,created'
        }
      } else if (!sortInfo.sortDesc) {
        sort = `-${sortInfo.sortBy}`
      } else {
        sort = sortInfo.sortBy
      }
      this.orderBy = sort
      this.fetchData()
    },
    format(num) {
      const reg = /\d{1,3}(?=(\d{3})+$)/g
      return (`${num}`).replace(reg, '$&.')
    },
    filterPaid() {
      this.paidOrders = this.childOrders.filter(a => a.node.status === 'A_7')
    },
    getElement(id = null) {
      if (id != null) {
        this.$router.push({ name: 'subOrders', params: { idFinalOrder: id } })
      } else {
        this.$router.push({ name: 'orders' })
      }
    },
    clickRow(data) {
      const { id } = data[0].node
      this.myId = id
      if (id != null) {
        this.$router.push({ name: 'subOrders', params: { idFinalOrder: id } })
      } else {
        this.$router.push({ name: 'orders' })
      }
    },
    showDropdown(node) {
      let show = false
      if (node.isSpecial && node.shippingCost === 0) {
        show = true
      }

      if (node.status === 'A_7') {
        show = true
      }
      if (node.status === 'A_10') {
        show = true
      }
      if (node.status === 'A_14') {
        show = true
      }

      return show
    },
    cleanUsername,

    fetchData() {
      this.isBusy = !this.isBusy

      const query = `{
                allVendors(id:"${this.vendor}") {
                    edges {
                    node {
                        id
                        vendorName
                        cartVendor(orderBy:"${this.orderBy}",first:${this.perPage},offset:${this.perPage * (this.currentPage - 1)})  {
                        totalCount
                        edges {
                            node {
                            id
                            orderId
                            isSpecial                            
                            shippingCost
                            shippingId
                            status
                            totalAmount
                            totalRefunded
                            chargeToken
                            paymentDate
                            created
                            customer{
                                username
                            }
                            vendor {
                                id
                                vendorName
                              }
                              orderVendorPay {
                                edges {
                                  node {
                                    id
                                  }
                                }
                              }
                            products { 
                                edges {
                                    node {
                                        id
                                        itemId
                                        itemName
                                        itemModel
                                        itemPrice
                                        itemBasePrice
                                        itemQuantity
                                        itemTaxId
                                        itemImage
                                        itemVariantId

                                    }
                                }
                            }
                            }
                        }
                        }
                    }
                    }
                }
                }`
      axios
        .post('', { query }).then(result => {
          messageError(result, this)

          this.childOrders = result.data.data.allVendors.edges[0].node.cartVendor.edges
          this.rows = result.data.data.allVendors.edges[0].node.cartVendor.totalCount
          this.isBusy = !this.isBusy
        }).catch(err => {
          console.log(err)
          this.isBusy = !this.isBusy
        })
    },
    getVendor() {
      const query = `{
            allVendors(client:"${this.userData.profile.client.id}",order:true,${this.idFinalOrder ? `parentOrder:"${this.idFinalOrder}"` : ''}) {
                totalCount
                edges {
                    node {
                        id
                        name
                        vendorName
                        
                        cartVendor {
                        edges {
                        node {
                            id
                            orderId
                            
                        }
                        }
                    }                    
                    client {
                        id
                        name
                    }
                    }
                }
                }
            }`

      axios
        .post('', { query }).then(result => {
          messageError(result, this)

          this.allVendors = result.data.data.allVendors.edges
        }).catch(err => {
          console.log(err)
        })
    },
    clickPickr() {
      document.getElementById('pickr').click()
    },

    getPayMethod() {
      axios
        .post('', {
          query: `
              query{
                client(id:"${this.userData.profile.client.id}"){
                  externalPayMethod
                  currency
                }
            }
        `,
        })
        .then(res => {
          messageError(res, this)
          this.currency = res.data.data.client.currency
        })
        .catch(() => {
        })
    },
    fechaFormato(value) {
      const date = new Date(value)
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }
      return value != null
        ? new Intl.DateTimeFormat('default', options).format(date)
        : value
    },
    getImage(data) {
      let path = '@/assets/images/backend/nocover.jpeg'
      if (data.length !== 0) {
        data.forEach(element => {
          path = element.http_path
        })
      }
      return path
    },
    errorImg(e) {
      e.target.src = fileError
    },
    buildImageUrl(folder) {
      return folder.imageVendorUrl == null || folder.imageVendorUrl.length === 0
        ? noCover
        : folder.imageVendorUrl
    },
    specialOrder(node, next) {
      if (next) {
        this.$swal({
          title: this.$t('indicateShippingCosts'),
          input: 'number',
          html: this.$t('orderTotalCost', { totalAmount: node.totalAmount, currencyType: this.currencyType[this.currency] }),
          inputPlaceholder: this.$t('shippingCosts'),
          didOpen: () => {
            const input = this.$swal.getInput()
            input.oninput = () => {
              const b = this.$swal.getHtmlContainer().querySelector('b')
              const val = parseFloat(input.value)
              const suma = node.totalAmount + (val >= 0 ? val : 0)
              b.textContent = suma
            }
          },
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
          inputAttributes: {
            autocapitalize: 'off',
            step: '0.01',

          },
          preConfirm: result => {
            if (result === null || result === '' || result === undefined) {
              this.$swal.showValidationMessage(
                this.$t('mustEnterAmount'),
              )
            } else if (parseFloat(result) <= 0) {
              this.$swal.showValidationMessage(
                this.$r('mustEnterAmountGreaterZero'),
              )
            }
          },
          showCancelButton: true,
          confirmButtonText: this.$t('dataGeneric.save'),
          cancelButtonText: this.$t('dataGeneric.cancel'),
          showLoaderOnConfirm: true,
        }).then(r => {
          if (r.isConfirmed) {
            const query = `mutation{
                 updateOrder(id:"${node.id}",input:{status:A_5,shippingCost:${r.value},totalAmount:${parseFloat(r.value) + parseFloat(node.totalAmount)}}){
                   order{
                     id
                     status
                     
                   }
                 }
               }`
            axios
              .post('', { query })
              .then(res => {
                messageError(res, this)
                showToast(this.$t('orderUpdateSuccess'), 1, this)
                this.fetchData()
              })
              .catch(() => {
                showToast(this.$t('error'), 2, this)
              })
          }
        })
      } else {
        this.$swal({
          title: this.$t('code.title'),
          text: this.$t('continueCancelOrder'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t('yNext'),
          cancelButtonText: this.$t('dataGeneric.cancel'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.isConfirmed) {
            const query = `mutation{
                updateOrder(id:"${node.id}",input:{status:A_6,isSpecial:false}){
                  order{
                    id
                    status
                    
                  }
                }
              }`
            axios
              .post('', { query })
              .then(res => {
                messageError(res, this)
                showToast(this.$t('cancelledOrder'), 1, this)
                this.fetchData()
              })
              .catch(() => {
                showToast(this.$t('error'), 2, this)
              })
          }
        }).catch(() => {

        })
      }
    },
    changeStatusOrder(node, status, packlink = false) {
      let text = ''
      switch (status) {
        case 'A_10':
          text = this.$t('orderBeInTransit')
          break
        case 'A_11':
          text = this.$t('finishOrder')
          break
        case 'A_8':
          text = this.$t('indicateAsReturnedOrder')
          break
        default:
          break
      }
      let name = ''
      let id = ''

      this.$swal({
        title: this.$t('code.title'),
        text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('yNext'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          if (packlink) {
            this.$swal({
              title: this.$t('changeOrderStatus'),
              html: `<h3>${this.$t('indicateIdOrderAndNameCarrier')}</h3><br/><div >
          <input id="name_packlink" autocapitalize="off"  class="swal2-input" :placeholder="${this.$t('nameCarrier')}" type="text" style="display: flex;"/>
          <input id="id_packlink" autocapitalize="off"  class="swal2-input" :placeholder="${this.$t('idPackLink')}" type="text" style="display: flex;"/>
          </div>`,

              preConfirm: () => {
                name = document.getElementById('name_packlink').value
                id = document.getElementById('id_packlink').value

                if (name === null || name === '' || name === undefined) {
                  this.$swal.showValidationMessage(
                    this.$t('errorIntroduceName'),
                  )
                  document.getElementById('name_packlink').classList.add('swal2-inputerror')
                } else {
                  document.getElementById('name_packlink').classList.remove('swal2-inputerror')

                  if (id === null || id === '' || id === undefined) {
                    this.$swal.showValidationMessage(
                      this.$t('errorIntroduceId'),
                    )
                    document.getElementById('id_packlink').classList.add('swal2-inputerror')
                  } else {
                    document.getElementById('id_packlink').classList.remove('swal2-inputerror')
                  }
                }
              },
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
              },
              buttonsStyling: false,

              showCancelButton: true,
              confirmButtonText: this.$t('dataGeneric.save'),
              cancelButtonText: this.$t('dataGeneric.cancel'),
              showLoaderOnConfirm: true,
            }).then(res => {
              if (res.isConfirmed) {
                const query = `mutation{
                                    updateOrder(id:"${node.id}",input:{status:${status},shippingId:"${id}",shippingName:"${name}"}){
                                        order{
                                            id
                                            status
                                        }
                                    }
                                }`
                axios
                  .post('', { query })
                  .then(r => {
                    messageError(r, this)
                    showToast(this.$t('orderUpdateSuccess'), 1, this)
                    this.fetchData()
                  })
                  .catch(() => {
                    showToast(this.$t('error'), 2, this)
                  })
              }
            })
          } else {
            const query = `mutation{
                            updateOrder(id:"${node.id}",input:{status:${status}}){
                                order{
                                    id
                                    status
                                }
                            }
                        }`
            axios
              .post('', { query })
              .then(r => {
                messageError(r, this)
                showToast(this.$t('orderUpdateSuccess'), 1, this)
                this.fetchData()
              })
              .catch(() => {
                showToast(this.$t('error'), 2, this)
              })
          }
        }
      }).catch(() => {
      })
    },
    stripeWithdrawal() {
      // TODO al realizar pago, crear un registro en withdrawal y cambiar el estado de las ordenes a pagado
      let vendor = null
      let amount = 0
      this.paidOrders.forEach(element => {
        if (!isEmpty(element.node.vendor)) {
          vendor = element.node.vendor.id
        } else {
          showToast(this.$t('errorPaymentOrderNotSeller'), 2, this)
          return
        }
        amount += element.node.totalAmount
      })

      const query = `{
                withdrawalStripePaid(vendor:"${vendor}",totalAmount:"${amount}") {
                    status
                }
            }`

      axios
        .post('', {
          query,
        }).then(result => {
          messageError(result, this)
        }).catch(err => {
          console.log(err)
        })
    },
  },

}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-flatpicker.scss";

#orders_element .actions {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: rgb(0 15 51 / 73%);
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
}

#orders_element .cardContent img {
    height: 11vw;
    object-fit: cover;
    /*width: 100%;
        height:  100%;
        object-fit: cover;*/
}

#orders_element .card-body {
    padding: 1rem;
}

#orders_element .card-body h4 {
    font-size: 1rem !important;
}

#orders_element .card-header {
    padding: 1rem;
}

#orders_element .card-header h4 {
    font-size: 1rem !important;
}

#orders_element .card-body .actions {
    display: -webkit-flex;
    display: flex;
    transition: all 0.2s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 2.75rem;
    padding-right: 0.34375rem;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;

    justify-content: space-between;
}

#orders_element #acortar {
    overflow: hidden;
    text-overflow: ellipsis;
}

#orders_element #acortarPadre {
    overflow: hidden;
    white-space: nowrap;
}
</style>
